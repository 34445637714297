import React, { useState, useEffect } from "react";
import "./ScrollDownButton.css";
import { motion } from "framer-motion";
import { MeniButtonAnimation } from "../animations.jsx";

function ScrollDownButton() {
  const targetPosition = window.innerHeight;
  const transitionValues = {
    duration: 0.8,
    repeat: Infinity,
    repeatType: "reverse", // Used to be 'yoyo' in some contexts, 'reverse' is more commonly understood
    ease: "easeOut",
  };
  const handleScrollDown = () => {
    window.scrollTo({ top: targetPosition, behavior: "smooth" });
  };

  return (
    <motion.div
      variants={MeniButtonAnimation}
      initial="show"
      animate="show"
      className="scrollDownBtnSlider"
    >
      <motion.button
        animate={{
          scale: [1, 1.5, 1.5, 1, 1],
          borderRadius: ["0%", "0%", "50%", "50%", "0%"],
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 0.5,
        }}
        onClick={handleScrollDown}
      >
        <i class="fa-solid fa-chevron-down"></i>
      </motion.button>
    </motion.div>
  );
}

export default ScrollDownButton;
